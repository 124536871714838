import React, { Suspense } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import DanGNelson from "./views/DanGNelson";
const MovieVaultLogin = React.lazy(() => import("./views/MovieVaultLogin"));
const MovieVault = React.lazy(() => import("./views/MovieVault"));

function Loader() {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <i className="fa-solid fa-spinner fa-spin-pulse" />
    </div>
  );
}

function AppRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <DanGNelson />
            </Suspense>
          }
        />
        <Route
          path="vault-login"
          element={
            <Suspense fallback={<Loader />}>
              <MovieVaultLogin />
            </Suspense>
          }
        />
        <Route
          path="movie-vault"
          element={
            <Suspense fallback={<Loader />}>
              <MovieVault />
            </Suspense>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default AppRoutes;
