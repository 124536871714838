import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileImage from "./profile.jpg";
import "./DanGNelson.css";

function App() {
  const navigate = useNavigate();
  const [headClicks, setHeadClicks] = useState(0);

  function clickHead() {
    if (headClicks < 9) {
      setHeadClicks(headClicks + 1);
    } else if (headClicks === 9) {
      setHeadClicks(headClicks + 1);
      setTimeout(() => {
        navigate("/vault-login");
        setHeadClicks(0);
      }, 900);
    }
  }

  return (
    <div
      id="dangnelson"
      className="d-flex align-items-center justify-content-center pb-3"
      style={{ minHeight: "100%" }}
    >
      <div className="d-flex flex-column" style={{ maxWidth: 350 }}>
        <div className="pb-4 d-flex flex-column align-items-center text-center">
          <div
            className="profile-container position-relative w-75 mb-3 rounded-circle "
            style={{
              paddingBottom: "75%",
              userSelect: "none",
              WebkitUserSelect: "none",
            }}
          >
            <div className="profile-border-thin position-absolute rounded-circle" />
            <div
              className={
                "position-absolute " + (headClicks === 10 ? "rotate" : "")
              }
              style={{
                userSelect: "none",
                WebkitUserSelect: "none",
                cursor: "not-allowed",
                transform:
                  "rotate(" +
                  (headClicks & 1
                    ? headClicks * 20 + "deg"
                    : "-" + headClicks * 20 + "deg") +
                  ")",
              }}
              onClick={clickHead}
            >
              <img
                src={ProfileImage}
                className="w-100 img-fluid rounded-circle"
                alt="logo"
              />
            </div>
          </div>

          <h1>Dan G Nelson</h1>
          <p className="m-0">
            <i className="fa-fw fa-solid fa-location-dot"></i> Richmond, VA
          </p>
        </div>
        <div className=" d-flex flex-column">
          <a
            href="https://www.linkedin.com/in/dgnelson/"
            role="button"
            type="button"
            className="btn btn-lg btn-outline-dark mb-2"
          >
            <i className="fa-fw fa-brands fa-linkedin"></i> Linkedin
          </a>

          <a
            href="./DanNelsonResume.pdf"
            role="button"
            type="button"
            className="btn btn-lg btn-outline-dark mb-2"
          >
            <i className="fa-fw fa-solid fa-file-pdf"></i> Resume
          </a>

          {/* <a
          href="https://github.com/dangnelson"
          role="button"
          type="button"
          className="btn btn-lg btn-outline-dark mb-2"
        >
          <i className="fa-fw fa-brands fa-github"></i> GitHub
        </a> */}

          <a
            href="mailto:dan.g.nelson@gmail.com"
            role="button"
            type="button"
            className="btn btn-lg btn-outline-dark mb-2"
          >
            <i className="fa-fw fa-solid fa-envelope"></i> Email
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
